// /* Global Dialogs Styles */
// /* rotate button on click */
/* rotate button on click */
.clickedDomain {
	animation: rotate 200ms ease-out 0s;
	animation-fill-mode: forwards;
}

.clickOUOpen {
  animation: rotate-ou 0.5s ease-out 0s;
  animation-fill-mode: forwards;
}

.clickedSubOU {
  animation: rotate 1s ease-out 0s;
  animation-fill-mode: forwards;
}


.faa-spin {
	animation: spin-animation 0.75s infinite;
	display: inline-block;
}

.icon-spinner {
	animation: spin-animation 1s infinite;
	display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes spin-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(90deg);
	}
}

@keyframes rotate-ou {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(90deg);
	}
}

@keyframes rotate-reverse {
	0% {
		transform: rotate(90deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes subtle-background-movement {
  0% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 70% 70%;
  }
  100% {
    background-position: 50% 50%;
  }
}