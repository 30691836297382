.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #eeefef !important;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

.mat-mdc-text-field-wrapper {
  height: 56px;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0;
}


@media screen and (max-width: 768px) {
  /* kc-2178 - allows users to touch and scroll action menus on mobile */
  .mat-mdc-option {
    user-select: all !important;
    -webkit-user-drag: auto !important;
    touch-action: auto !important;
    -webkit-tap-highlight-color: transparent !important;
  }
}

.optionGroup {
  font-weight: bold;
  font-style: italic;
}

.optionChild {
  padding-left: 25px;
}

.mdc-menu-surface.mat-mdc-select-panel {
  min-width: 400px; 
}