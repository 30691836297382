ul {
    list-style: none;
    margin-bottom: 0;
}

.list-group {
    list-style: none;
}

// .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
//     padding-top: 14px;
//     padding-bottom: 0; 
// }

.mat-mdc-form-field-flex {  
    padding-right: 12px;
}

.mat-stepper-horizontal-line {
    margin: 0 !important;
}

// Suggestion lists
.suggestion-item-active {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .suggestion-item-list {
    background-color: var(--bs-white) !important;
    min-width: 200px !important;
    z-index: 1 !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px !important;
    overflow-y: auto;
    max-height: 300px;
  }
  
  .suggestion-item-list::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
  
  .suggestion-item-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .suggestion-item-list::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
    border-radius: 10px;
  }

  .mdc-list-group__subheader {
    margin: 0 !important;
    padding: calc((3rem - 1.5rem)/2) 16px;
    background-color: var(--bs-light);
}

// .mat-mdc-list overrites 
  
.mdc-list {
  margin: 0;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, 
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, 
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: center !important;
  margin-top: 0 !important;
  margin-left: 16px;
  margin-right: 8px;
} 

// .mat-mdc-list,
// .mat-mdc-list-base,
// .mdc-list {
//   &:hover {
//     background-color: var(--bs-light) !important;
//   }
// }

.mdc-list-item--with-leading-icon.mdc-list-item {

}

.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {

}



.list-group {
  padding-left: 0;
	display: flex;
	flex-direction: column;
	.break-text {
		word-break: break-all;
	}
	
	.list-group-item {
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);

		.list__title, 
		.list__subtitle {
			display: block;
			flex: 0 1 50%;
		}

    &:nth-child(even) {
			background-color: var(--bs-gray-100);	
    }

    &:nth-child(odd) {
      background-color: var(--bs-white);
    }
	}
}
