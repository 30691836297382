.graph {
  &__icons {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
    }

    span {
      font-size: 0.875rem;
      margin-right: 1rem;
      &::before {
        font-size: 16px;
        margin-right: 4px;
      }
    }

    .circleTotal:before {
      content: "\25CF";
      color: var(--bs-red);
    }

    .circleDrive:before {
      content: "\25CF";
      color: var(--bs-primary);
    }

    .circle:before {
      content: "\25CF";
    }

    .circleGmail:before {
      content: "\25CF";
      color: var(--bs-warning);
    }

    .circlePhotos:before {
      content: "\25CF";
      color: var(--bs-success);
    }
  }
}

/* settings table */
/* settings global tables  */
.striped-table,
.cdk-table,
.mat-table {
  .mat-mdc-row:nth-child(even) {
    background-color: var(--bs-gray-100);

    &:hover {
      background-color: var(--bs-gray-100);
    }
  }

  .mat-mdc-row:nth-child(odd) {
    background-color: var(--bs-white);

    &:hover {
      background-color: var(--bs-white);
    }
  }

  .clickable-row {
    &:hover {
      cursor: pointer;
      background-color: aliceblue !important;
    }
  }

  .mdc-icon-button {
    color: var(--bs-gray-600);
  }

  .mat-mdc-cell,
  .mat-mdc-row {
    border-bottom-color: rgba(0, 0, 0, 0.06);
  }

  .mat-mdc-header-cell h4 {
    font-size: 14px;
    font-weight: 400;
  }

  .mat-mdc-cell {
    font-size: 13px;
    height: 72px !important;
  }

  .mat-mdc-cell_import {
    height: 20px !important;
  }
  .text-muted {
    margin-bottom: 0px;
  }

  .mat-mdc-no-data-row {
    text-align: center;
  }
}

.mat-mdc-table .mat-mdc-row:hover,
.mat-mdc-table .mat-mdc-footer-row:hover {
  background-color: none !important;
}

.table-footer-item-count {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

.cdk-column-select {
  padding-left: 4px !important;
}

// SETTINGS TABLES
.settings {
  &__actions {
    cursor: pointer;
    opacity: 1;
    transform: translateX(10px);
  }

  &__action {
    transition: 200ms all ease-in;
    color: var(--bs-gray-500);
    transform: scale(0.7);
  }

  &__action:hover {
    transform: scale(1);
  }

  &__action--delete:hover {
    color: var(--bs-red);
  }

  &__action--edit:hover {
    color: var(--bs-primary);
  }

  /* settings  */
  &__header {
    border-top: none;
    background-color: var(--bs-gray-200);
  }
}

.table-responsive {
	border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);


  &.has-active .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
    cursor: pointer !important;
    transition: 50ms ease-in all;
    background-color: aliceblue !important;
  }

  scrollbar-width: thin;
	&:hover {
		overflow-x: auto;
	}

	&::-webkit-scrollbar {
		display: block;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--bs-light);
		height: 2px;
		width: 2px;
	}

	&::-webkit-scrollbar {
		width: 4px;
		height: 3px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--bs-gray-300);
		border-radius: 8px;
		border: 3px solid transparent;
	}

  th {
    white-space: nowrap;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  td {
    white-space: nowrap;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
  
.cdk-table {
	transition: 200ms ease-in all;

  .table-alert {
		position: absolute;
		left: 4px;
		height: auto;
		top: 20px;
		width: auto;
		font-size: 1.3rem;
		transform: scaleX(-1) !important;

		&:before {
			content: "";
			height: 80%;
			width: 80%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: var(--bs-white);
			border-radius: 4px;
			z-index: -1;
		}
	}
}