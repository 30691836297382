.mat-step-header .mat-step-icon {
    // color: white;
}

.mat-step-icon-content {
    // color: white;
}

.mat-stepper-vertical {
    color: var(--bs-white);
}

.mat-horizontal-stepper-header-container {
    color: var(--bs-white);
}