.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color:  var(--bs-yellow);
}

.mat-mdc-tab-header-pagination-chevron {
    border-color:  var(--bs-link-color);
}

.mat-tab-body-content {
    overflow: hidden !important;
    overflow-x: hidden !important;
  }

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: linear-gradient(351deg, #ffaa01 0%, rgba(255, 134, 0, 1) 100%);
  height: 2px;
}

.mat-tab-label-active.mat-tab-label {
  opacity: 1;
  color: var(--bs-primary);
  font-weight: bold;
}

.mat-tab-label-active {
  opacity: 1;
}

.mat-tab-header {
    border-bottom: none;
}
  
.mat-tab-label-active {
    opacity: 1 !important;
}

.mat-tab-label {
    color: var(--bs-primary);
}
  
  /* override material theme styles */
.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ffaa01;
}
