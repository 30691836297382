.mat-chip.mat-standard-chip {
  color: rgba(0, 0, 0, 0.67);
}

/* Some Angular mat-chips need to not be clickable */
.un-clickable-chip {
  pointer-events: none;
}

.un-clickable-chip:focus,
.un-clickable-chip:active {
  appearance: none;
}

.un-clickable-chip::after {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}


