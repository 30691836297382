.mat-mdc-chip-avatar {
    border-radius: 100%;
}

.mat-chip.mat-standard-chip {
    background-color: #efeff0;
  }
  
  .mat-chip-with-avatar {
    font-size: 12px;
  }