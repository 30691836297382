a {
    text-decoration: none;
    color: var(--bs-link-color);
    font-weight: 400;
    cursor: pointer;
    &:hover {
      color: var(--bs-link-hover-color);
      text-decoration: none;
    }
  }


  .hovered {
    &:hover {
      background-color: var(--bs-light);
    }
  }

  .alert {
    // display: flex;
    // align-items: center;
  }

.link-un-styled,
.link-un-styled:link,
.link-un-styled:hover {
  color: inherit;
  text-decoration: inherit;
}


// a.active-link {
//   color: black !important;
// } 


.inline-block-links {
  a {
    display: inline-block;
  }
}