//Required
@import "../../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
//  $font-family-sans-serif: var(--bs-body-font-family);
// $font-family-monospace: "Nunito", sans-serif;
// $font-family-base: var(--bs-body-font-family);
//Rquired
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/variables-dark.scss";

$primary: #114c70;
$secondary: #356785;

$success: #198754;
$info: #0dcaf0;
$warning: #ffaa01;
$danger: #d62518;
$light: #f6f6f6;
$dark: #343a40;

$blue:    #001D2B;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #ffaa01;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$custom-color: #ff4500;

// 3. Include remainder of required Bootstrap stylesheets


// First override some or all individual color variables

// Then add them to your custom theme-colors map, together with any additional colors you might need
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  // add any additional color below
  custom-color: $custom-color,
);

$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800,
  "light":      $light,
  "custom-color": $custom-color
);

// Create your own map
$custom-colors: (
  "custom-color": $custom-color,
);

$theme-colors: map-merge($theme-colors, $custom-colors);


@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../../../../node_modules/bootstrap/scss/utilities";

$all-colors: map-merge-multiple($blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans);

$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab,
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
      // Negative margin utilities
    "negative-margin": (
      responsive: true,
      property: margin,
      class: m,
      values: $negative-spacers
    ),
    "negative-margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: $negative-spacers
    ),
    "negative-margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: $negative-spacers
    ),
    "negative-margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values: $negative-spacers
    ),
    "negative-margin-end": (
      responsive: true,
      property: margin-right,
      class: mr,
      values: $negative-spacers
    ),
    "negative-margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: $negative-spacers
    ),
    "negative-margin-start": (
      responsive: true,
      property: margin-left,
      class: ml,
      values: $negative-spacers
    ),

    "margin-start":
      map-merge(
        map-get($utilities, "margin-start"),
        (
          class: ml,
        )
      ),

      "margin-end": map-merge(
        map-get($utilities, "margin-end"),
        ( class: mr ),
      ),

      "padding-start":
      map-merge(
        map-get($utilities, "padding-start"),
        (
          class: pl,
        )
      ),

      "padding-end": map-merge(
        map-get($utilities, "padding-end"),
        ( class: pr ),
      ),

      "color": map-merge(
        map-get($utilities, "color"),
        (
          values: map-merge(
            map-get(map-get($utilities, "color"), "values"),
            (
              $all-colors
            ),
          ),
        ),
      ),
      "font-size": (
        rfs: true,
        property: font-size,
        class: fs,
        values: $font-sizes
      ),
    )
  );

  $enable-negative-margins: true;


// 5. Optionally include utilities API last to generate classes based on the Sass map in
@import "../../../../node_modules/bootstrap/scss/utilities/api";


@import "../../../../node_modules/bootstrap/scss/type";
@import "../../../../node_modules/bootstrap/scss/reboot";

@import "../../../../node_modules/bootstrap/scss/images";
@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/helpers";
@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/buttons";
@import "../../../../node_modules/bootstrap/scss/spinners";
@import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/progress";
@import "../../../../node_modules/bootstrap/scss/alert";
@import "../../../../node_modules/bootstrap/scss/toasts";
@import "../../../../node_modules/bootstrap/scss/tables";
// @import "../../../../node_modules/bootstrap/scss/card";


:root {
  scroll-behavior: smooth;
}

$enable-rfs: false;




// BS root var reference 

:root {
    --bs-blue: #0d6efd;
    // --bs-indigo: #6610f2;
    // --bs-purple: #6f42c1;
    // --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14; 
    --bs-yellow: #ffaa01;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    // --bs-black: #000;
    --bs-white: #fff;
    
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e6e6e6;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #114c70;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;                                              
    --bs-danger: #dc3545;
    --bs-light: #f6f6f6;
    --bs-dark: #212529;
    --bs-primary-rgb: 17, 76, 112;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    //  --bs-font-sans-serif: var(--bs-font-sans-serif);
    // --bs-font-monospace:'-apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”,  “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif;'
    // --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    // --bs-body-font-family: var(--bs-font-sans-serif);
    // --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    // --bs-body-line-height: 1.5;
    --bs-body-color: #222;
    --bs-body-bg: #fefefe;
    // --bs-border-width: 1px;
    // --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    // --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    // --bs-border-radius: 0.375rem;
    // --bs-border-radius-sm: 0.25rem;
    // --bs-border-radius-lg: 0.5rem;
    // --bs-border-radius-xl: 1rem;
    // --bs-border-radius-2xl: 2rem;
    // --bs-border-radius-pill: 50rem;
    --bs-link-color: #356785;
    --bs-link-hover-color: #3183b7;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --bs-alert-bg: #f6f6f6;
}


$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px
);

$enable-gradients: true;
// $enable-dark-mode: true;
// $color-mode-type: data;
// @include media-breakpoint-up($name, $breakpoints: $grid-breakpoints);

// Examples
// @include media-breakpoint-up(sm) {  }
// @include media-breakpoint-up(md) {  }
// @include media-breakpoint-up(lg) {  }
// @include media-breakpoint-up(xl) {  }
// @include media-breakpoint-up(xxl) {  }

// @media (prefers-color-scheme: dark) {
//   :root {
//     --body-bg: pink;
//     --body-color: #6c757d;
//     --link-color: #dee2e6;
//     --link-color-hover: #fff;
//   }
// }




