// disables padding for ul 
.list__top-links {
  padding-left: 0;
}

.side-nav {
  overflow-y: auto;
}

.leftnav {
display: flex;
flex-direction: column;
height: 100%;

  .mat-mdc-list-base {
    height: 100%;
    padding: 30px 0 16px 0px;
  }

  .small, small {
    font-size: 12px;
  }
}

.menu-link, 
.submenu-link {
  transition: 100ms ease-in-out color;
  color: var(--bs-gray-600);
}

.menu-link,
.submenu-link {
  display: flex;
  align-items: center;
  height: 42px;
  margin: 3px 0;

  &.active-link,
  &:hover {
    background-color: var(--bs-light);
    color: var(--bs-body-color);
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    z-index: 1;
  }
}

.list-link {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.leftnav__link {
  display: flex;
  flex-flow: column;

  // makes active full width
  & > .menu-link {
    padding-left: 22px;
  }
}

.list__bottom-links {
  .list-link {
    padding-left: 22px;
  }
}

// target children to make active not subject to padding/margin etc
.submenu-links > .submenu-link { 
  padding-left: 48px;
}

.list-link__icon {
  font-size: 20px;
  margin-right: 8px;
}

.list-link__name, 
.submenu-link {
  opacity: 1;
  transition: 300ms transform ease-in-out, opacity 400ms ease-in-out;
}

.submenu-links {
  position: relative; 
  transform: translateX(0);
  transition: 300ms transform ease-in-out, opacity 400ms ease-in-out;
}

.side-nav__links.closed {
  .list-link__name, 
  .submenu-links {
    visibility: hidden; 
    opacity: 0;
    transform: translateX(-400px);
  }

  .submenu-link {
    display: none;
  }

  .list-link__icon {
    transition: 400ms transform ease-in-out, 50ms color ease-in-out;
    transform: scale(1.4);
    color: var(--bs-primary);
  }
}

.left-nav-footer {
  transform-origin: bottom left;
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: auto;
  padding-bottom: 24px;
  transition: 300ms transform ease-in-out, opacity 400ms ease-in-out;
}

.side-nav.closed {
  width: var(--side-nav-just-icons);
  .left-nav-footer {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-200px);
  }
}