.tooltip-wrapper {

  &:hover {
    .mat-html-tooltip {
      visibility: visible;
      transform: scale(1);
      opacity: 1;
    }

    .tooltip-icon {
      color: var(--bs-primary);
      transform: scale(1.1);
    }
  }
}

.tooltip-icon {
  transition: 100ms all ease-in-out;
  z-index: 98;
}

.mat-html-tooltip:hover {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.mat-html-tooltip {
    cursor: default;
    transition: 50ms all ease-in-out;
    position: absolute;
    background-color: #616161;
    color: var(--bs-white); 
    font-size: 12px; 
    padding: 8px 12px; 
    border-radius: 4px; 
    width: 200px;
    visibility: hidden;
    z-index: 99;
    transform: scale(0);
    opacity: 0;
    top: 26px;

    a {
      color: var(--bs-orange);
      cursor: pointer;
      display: inline;

      &:hover {
        color: lighten(#fd7e14, 10%);
      }
    }
}

