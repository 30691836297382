.mdc-dialog__title {
	background-color: var(--bs-primary);
  display: flex !important;
  align-items: center !important;
  margin: auto !important;
  padding: 12px 24px 12px 24px !important;
  white-space: nowrap !important; 
}

.mat-mdc-dialog-container .mdc-dialog__title {
  color: var(--bs-white) !important;
  font-size: 18px !important;
  line-height: 1.5 !important;
}

.mat-mdc-dialog-container, 
.mdc-dialog__container  {
  min-width: 400px;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
}

.mat-mdc-dialog-actions {
  margin-top: 16px !important;
}

.mdc-dialog .mdc-dialog__content {
  margin-top: 32px !important;
  padding: 0 24px 20px 24px !important;
}

.mat-mdc-dialog-container {
  min-width: 400px;
}
