button  {
  letter-spacing: 1px;
}

// for overriding bootstrap bg colors
.mat-mdc-button[disabled], 
.mat-mdc-unelevated-button[disabled], 
.mat-mdc-raised-button[disabled], 
.mat-mdc-outlined-button[disabled] {
    opacity: .5;
}

.mat-mdc-outlined-button {
  border-radius: 22px !important;
}

.mdc-icon-button {
    font-size: 0 !important;
}

  .mat-mdc-outlined-button {
    font-weight: 500;
    font-weight: semibold;
    border-radius: 22px !important;
    transition: all 100ms ease-in-out !important;

    &.delete, 
    &.danger {
      border-color: var(--bs-red) !important;
      color: var(--bs-red) !important;

      &[disabled] {
        border-color: transparent !important;
        color: var(--bs-white) !important;
        background-color: rgba(220, 53, 69, .3) !important;
      }

      &:hover {
        border-color: var(--bs-red) !important;
        background-color: var(--bs-red) !important;
        color: var(--bs-white) !important;
      }
    }

    &.success {
      border-color: var(--bs-green) !important;
      color: var(--bs-green) !important;
      &[disabled] {
        background-color: rgb(25 135 84 / 50%) !important;
        border-color: transparent !important;
        color: var(--bs-white) !important;
      }

      &:hover {
        background: var(--bs-green) !important;
        border-color: var(--bs-green) !important;
        color: var(--bs-white) !important;
      }
    }

    &.create {
      border-color: var(--bs-orange) !important;
      color: var(--bs-orange) !important;
      &[disabled] {
        background-color: lighten(#fd7e14, 20%) !important;
        border-color: transparent !important;
        color: var(--bs-white) !important;
      }

      &:hover {
        background: var(--bs-orange) !important;
        color: var(--bs-white) !important;
      }
    }

    &.export {
      border-color: var(--bs-secondary) !important;
      color: var(--bs-secondary) !important;
      &[disabled] {
        background-color: rgb(108 114 125 / 74%) !important;
        border-color: transparent !important;
        color: var(--bs-white) !important;
      }

      &:hover {
        background: var(--bs-secondary) !important;
        color: var(--bs-white) !important;
      }
    } 

    &.primary {
      border-color: var(--bs-primary) !important;
      color: var(--bs-primary) !important;
      &[disabled] {
        background-color: rgba(17, 76, 112, .3) !important;
        border-color: transparent !important;
        color: var(--bs-white) !important;
      }

      &:hover {
        background: var(--bs-primary) !important;
        color: var(--bs-white) !important;
      }
    }

    &.white {
      transition: all 200ms ease-in-out !important; 
      border-color: var(--bs-white) !important;
      color: var(--bs-white) !important;

      &:hover {
        background:rgba(255, 255, 255, .1) !important;
        color: var(--bs-white) !important;
      }

      &--hover-green:hover {
        background-color: var(--bs-green) !important;
        border: 1px solid transparent !important;
      }

      &--hover-primary:hover {
        background: var(--bs-primary) !important;
        border: 1px solid var(--bs-primary) !important;
      }
    }
  }

  .mat-mdc-raised-button {
    font-weight: 500;
    letter-spacing: 1px;
    font-weight: semibold;
    border-radius: 22px !important;
    transition: all 200ms ease-in-out !important;

    &:hover {
      filter: brightness(0.8);
    }

    &.delete, 
    &.danger {
      background-color: var(--bs-red) !important;
      color: var(--bs-white) !important;
    }

    &.success {
      background-color: var(--bs-green) !important;
      color: var(--bs-white) !important;
    }

    &.create {
      background-color: var(--bs-warning) !important;
      color: var(--bs-white) !important;
    }

    &.export {
      background-color: var(--bs-secondary) !important;
      color: var(--bs-white) !important;
    } 

    &.primary {
      background-color: var(--bs-primary) !important;
      color: var(--bs-white) !important;
    }
  }

  /* Override primary button theme bg-color */
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  background-color: var(--bs-primary) !important;
}

.mat-mdc-unelevated-button.mat-primary:disabled,
.mat-mdc-raised-button.mat-primary:disabled,
.mat-mdc-fab.mat-primary:disabled,
.mat-mdc-mini-fab.mat-primary:disabled {

}

.mat-mdc-outlined-button:not(:disabled) {

}

.extended-fab {
  border-radius: 22px !important;
  color: var(--bs-white);
  background-color: #ffaa01;
  height: 40px;
}

.extended-fab:hover {
  /* color: var(--bs-primary); */
}

.extended-fab-primary {
  background: var(--bs-primary) !important;
  border-radius: 22px !important;
  color: var(--bs-white);
  height: 40px;
}
.extended-fab-primary:hover {
  color: #ffaa01;
}

.extended-fab-success {
  background: var(--bs-success) !important;
  color: var(--bs-white);
  border-radius: 22px !important;
  height: 40px;
}

svg > g > g:last-child {
  pointer-events: none;
}

.footer-btn {
  border-radius: 0 !important;
  background-color: var(--bs-light);
  height: 40px;
  border: none !important;
  border-radius: 0 !important;
  padding: 10px 0;
  width: 100%;

  &:active, &:focused {
    appearance: none;
}

  &:hover {
      transition: 100ms all ease-in;
      background: var(--bs-gray-100) !important;
  }
}

