:root {
  --mat-shadow: 0px 0px 0px -4px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(60, 39, 29, 0.12);
  --system-font-stack: “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”,
    sans-serif;
  --header-height: 65px;
  --breadcrumbs-height: 72px;
  --side-nav-width: 250px;
  --side-nav-just-icons: 60px;
  --z-index-header: 99;
}

html,
body {
  height: 100%;
  font-family: var(--system-font-stack);
  background: var(--bs-gray-100);
}

body {
  margin: 0;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
  background: transparent; /* Optional: just make scrollbar invisible */
  width: 0px; /* Remove scrollbar space */
}

.mat-shadow {
  box-shadow: var(--mat-shadow);
}

.no-shadow {
  box-shadow: none !important;
}

.mat-shadow-hover {
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden-x {
  overflow-x: hidden !important;
}

.no-hover {
  background-color: transparent !important;
  color: inherit !important;
  cursor: default !important;

  & > * {
    background-color: transparent !important;
    color: inherit !important;
    cursor: default;
  }
}

.rounded-0 {
  border-radius: 0 !important;
}

.NgxEditor__MenuBar {
  flex-wrap: wrap !important;
}
.fixed-space {
  padding-top: 78px;

  @include media-breakpoint-down(md) {
    padding-top: 8vh;
  }
}

.page-header__icons button:not(:last-child) {
  margin-right: 10px;

  .page-header__icons {
    top: 0;
    right: 0;
    position: relative;
  }
}
.page-header__icons {
  position: fixed;
  right: 12px;
  top: 77px;
  z-index: 100;
}

@media (min-width: 1200px) {
  .page-header__icon-more {
    display: none;
  }
}

@media (max-width: 1200px) {
  .page-header__icon {
    display: none;
  }
}

.page-header__icons {
  button {
    margin-right: 8px;

    button:last-of-type {
      margin-right: 0 !important;
    }
  }
}

.page-header__icons--workflows {
  top: 81px;
}

// positions button row inline and wraps
@media (max-width: 1024px) {
  .page-header__icons--workflows {
    position: relative;
    padding: 0 0 8px 0;
    top: 0;
    left: 0;
    display: flex;

    & > * {
      margin: 4px 0;
    }
  }
}

.optionGroup {
  font-weight: 700 !important;
  font-style: italic !important;
}

.optionGroup span {
  padding-right: 2.5px;
}

.optionChild {
  padding-left: 25px !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
