.mat-expansion-panel-body {
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 0 !important;
}

.mat-expansion-panel {
    padding: 0;
    background: var(--bs-white);
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mat-drawer-inner-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.mat-content > mat-panel-title,
.mat-content > mat-panel-description {
  flex: 0 0 auto;
}

.mat-expansion-panel-header {
  background: var(--bs-light);
}

.mat-expansion-panel-header.mat-expanded:focus {
  background: var(--bs-light) !important;
}

.mat-expansion-panel-header.mat-expanded:hover {
  background: var(--bs-gray-100) !important;
}

.mat-expansion-panel-header {
  font-size: 1rem;
  font-weight: 400;
}

.mat-panel-title {
  font-size: 1rem;
}