// tickets & agents 
.card--fixed-height {  
    min-height: 400px;
    box-shadow: none !important;

    @include media-breakpoint-down(lg) {
        min-height: auto;
    }

    .mat-icon {
      color: var(--bs-primary);
      overflow: visible;
    }

    .card__body {
      width: 100%;
      padding: 12px;
      transition: 100ms ease-in all;
      cursor: pointer;

      &:first-child {
        border-top: none !important;
     }
     &:last-child {
         border-bottom: none !important;
      }

      &:hover {
          background-color: var(--bs-gray-100);
      }
  
      & > .mat-icon {
          color: var(--bs-link);
      }
  
      a {
          color: var(--bs-black);
          transition: 200ms ease-in all;
      }
  
      p { 
          margin-bottom: 0;
      }
  }
}

.card {
  &__list-title {
    font-size: 13px;
  }

  &__list-subtitle {
    font-size: 11px;
  }

  &__footer-button {
    border: none !important;
    border-radius: 0 !important;
    padding: 10px 0;
    background: var(--bs-light) !important;
    font-weight: 400;


    &:active, &:focused {
        appearance: none;
    }

    &:hover {
        transition: 100ms all ease-in;
        background: var(--bs-gray-100) !important;
    }
}
}
