:root {
	--auto-grid-min-size: calc(25% - 40px);
}

.image-grid__file-name {
	z-index: 20;
	width: 100%;
	display: flex; 
	justify-content: center;
	background-color: white;
}
  
.image-grid__btn {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	transform: scale(.2);
	transition: all 100ms ease-in;
	z-index: 100000;
}
  
.image-grid__item:hover > .image-grid__btn {
	opacity: 1;
	cursor: pointer;
	visibility: visible;
	transform: scale(1);
} 
  
.image-grid__item .mat-icon::before  {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: var(---bs-white);
	height: 12px;
	width: 12px;
	z-index: -1;
}
  
.search-icon__button {
	z-index: 200;
	border-radius: 100%;
	min-width: 38px;
	background-color: transparent;
	box-shadow: none;
}
  
.mat-icon {
	position: relative;
}


/* Start of Attachments */
.image-grid {
  min-width: var(--auto-grid-min-size);
  gap: 6px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(24% - 24px), 1fr));
}

.image-grid__item,
.image-grid__pdf {
  cursor: pointer;
  min-width: var(--auto-grid-min-size);
  height: 17vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: box-shadow 100ms ease-in;
  overflow: hidden;
}

.image-grid__item img {
  min-width: var(--auto-grid-min-size);
}

.image-grid__pdf .mat-icon {
  font-size: 32px;
  height: auto !important;
  width: auto !important;
  place-self: center;
}

.image-grid__item:hover > img {
  transform: scale(1.3);
}

.image-grid__item:hover {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

.image-grid__item img {
  transition: transform 200ms ease-in;
  object-fit: contain;
  overflow: hidden;
}

/* End of Attachments */
