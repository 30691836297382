p, .p {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
}

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.6;
}

h2, .h2 {
  font-size: 1.3rem;
  font-weight: 400; 
  line-height: 1.8rem;
}

h3, .h3 {
  font-size: 1.125rem;
  line-height: 1.3rem;
}

h4, .h4 {
  font-size: 1rem;
  margin-bottom: 0; 
  line-height: 1.3rem;
}

h5, .h5 {
  font-size: 0.9rem;
  line-height: 1rem;
}

h6, .h6 {
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.title {
  color: var(--bs-primary);
  font-weight: 500;
}

